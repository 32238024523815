import cloneDeep from 'lodash.clonedeep';
import { translate } from 'vue-gettext';

const { gettext: $gettext } = translate;

export const applicationSteps = cloneDeep({
  instalmentLoan: {
    initial: [
      { text: $gettext('Preliminary informations'), name: 'start' },
      { text: $gettext('Basic information'), name: 'basicInformation' },
      { text: $gettext('Customer information'), name: 'customerQuestions' },
      { text: $gettext('Loan Offer'), name: 'loanOffer' },
      { text: $gettext('Confirmation'), name: 'instalmentLoanTerms' },
      { text: $gettext('Ready'), name: 'instalmentLoanComplete' },
    ],
  },
  continuousLoan: {
    initial: [
      { text: $gettext('Preliminary informations'), name: 'start' },
      { text: $gettext('Basic information'), name: 'basicInformation' },
      { text: $gettext('Customer information'), name: 'customerQuestions' },
      { text: $gettext('Confirmation'), name: 'continuousLoanTerms' },
      { text: $gettext('Ready'), name: 'complete' },
    ],
  },
});

export const sellerSteps = [];

export const soloSteps = cloneDeep([
  { name: 'applicationNotFound', text: $gettext('The Application was not found') },
  { name: 'connectionError', text: $gettext('Connection error') },
  { name: 'linkExpired', text: $gettext('Expired application') },
  { name: 'kreditz', text: $gettext('Ability to pay') },
  { name: 'reject', text: $gettext('The application was suspended') },
]);
